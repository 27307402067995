@import "./../../App.scss";


.fiche-eleve,
.fiche-moniteur,
.fiche-indispos,
.listeEleves,
.fiche-annulation {
    top: 20px !important;

    .newMdp {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        button {
            margin: 0;
        }

        input {
            margin: 0;
            font-size: 0.7em;
        }
    }

    &.fiche-annulation {
        top: 100px !important;
    }

    .buttons {
        display: flex;
    }

    .indispo {
        border-top: 1px solid $grisXLight;
        border-bottom: 1px solid $grisXLight;
        margin-top: 10px;
        font-size: 0.75em;
        text-align: center;

        span {
            font-family: 'interM';
            color: $gris;
            font-size: 1.2em;
        }
    }

    .titreListe {
        font-size: 1.1em;
        border-radius: 4px;
        padding: 10px;
        background-color: $vert;
        color: $blanc_casse;
    }

    .rechercher {
        margin: 0;
        border: 1px solid $grisLight;
        display: block;
        font-size: 0.8em;
        width: 100%;
        height: 50px;
        margin: auto;
        margin-top: 15px;
        padding: 10px;
        border-radius: 6px;
    }

    .liste, .scroll {
        // border: 1px solid red;
        margin-top: 15px;
        max-height: 550px;
        overflow-y: scroll;
        overflow-x: hidden;

        .eleve {
            border: 1px solid $grisLight;
            margin-top: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            border-radius: 6px;
            padding: 15px;
            color: $gris;
        }

        .aucunEleve {
            color: $saumon;
            text-align: center;

        }
    }

    .set-indispo {
        margin-top: 25px;

        p {
            text-align: center;

            strong {
                margin-top: 5px;
                display: inline-block;
                color: $gris;
            }
        }
    }

    .niv1 {
        overflow: hidden;

        .niv2 {

            width: 200%;
            box-sizing: border-box;
            display: flex;
            transition: transform 320ms cubic-bezier(.9, .45, .64, 1);



            .inpouts {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 250px;
                box-sizing: border-box;
                margin: 0;
                flex: 1;

                .cols {
                    margin-left: 5px;
                }

                &>.arrow {

                    margin: 0;
                    border-radius: 25px;
                    background-color: $vert;

                    img {
                        width: 100%;
                        transform: scale(1.2);
                        cursor: pointer;
                        display: block;
                        width: 40px !important;
                        height: 40px !important;
                    }

                }

                div {

                    h2 {
                        margin-top: 10px;
                        text-align: left;
                        display: flex;
                        align-items: center;

                        span {
                            width: 100px;
                            display: inline-block;
                        }

                        .vide {
                            border: 3px solid $saumon;
                            background-color: rgba($color: $saumon, $alpha: 0.5);
                        }
                    }
                }
            }
        }
    }

    .jour {
        // border: 3px solid red;
        color: $grisClair;
        margin-left: 20px;
        margin-top: 15px;

        h2 {
            // color: blue;
            font-size: 0.9em;
            font-family: "interR";
            margin-bottom: 0;
        }

        .ampm {
            // border: 1px solid blue;
            display: flex;
            align-items: center;
            margin-top: 5px;

            &>* {
                margin: 0;
            }

            h3 {
                // color: orange;
                font-size: 0.75em;
                font-family: "interL";
                font-weight: inherit;
                width: 80px;
            }

            input {
                margin: 0;
                // border: 1px solid purple;
                display: block;
                font-size: 0.8em;
                width: 15%;
                height: 25px;
                margin-right: 5px;
                margin-left: 5px;
            }

            button {
                display: block;
            }
        }
    }

    button {
        margin: auto;
        margin-top: 15px;
        margin-bottom: 5px;
        transition: opacity 250ms ease-in-out;
    }
}