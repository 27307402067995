@import './../../App.scss';

.calendrier {
    width: auto;
    min-height: 25px;
    color: $gris;

    header{
        padding: 0;
        margin: 0;
        button{
            width: 70px;
            margin: 0;
        }
    }

    .semaine {
        // border: 2px solid orange;
        display: flex;
        justify-content: space-between;
        width: auto;

        &>div {
            // border: 1px solid blue;
            margin: 5px;
            padding: 8px;
            cursor: pointer;
            width: 12px;
            height: 12px;
            text-align: center;
            border-radius: 100px;
            position: relative;
            font-size: 0.6em;
            line-height: 10px;

            &.today {
                background-color: $grisXLight;
            }

            &.beforeMonth,
            &.afterMonth {
                color: $grisXLight;
            }

            &.coursPrev {
                background-color: $grisLight;
                color: $blanc_casse;

                .pastille {
                    background-color: $grisLight;
                }
            }

            &.coursNext {
                background-color: $vert;
                color: $blanc_casse;

                .pastille {
                    background-color: $vert;
                }
            }

            &.examen {
                background-color: $saumon;
                color: $blanc_casse;

                .pastille {
                    background-color: $saumon;
                    width: 80px;
                    right: -65px;
                    font-size: 0.75em;
                }
            }

            .pastille {
                position: absolute;
                top: -10px;
                right: -6px;
                height: 18px;
                width: 18px;
                border-radius: 10px;
                font-size: 0.7em;
                line-height: 18px;
                font-family: "interR";
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }
}