@import './App.scss';

header {
    // border: 1px solid purple;
    height: 60px;
    margin-bottom: 10px;
    // margin-top: 10px;
    display: flex;
    padding-top: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;

    img {
        display: block;
        height: 100%;
    }

    button {
        display: block;
    }
}

.flex2{
    display: flex;
    justify-content: space-between;
    padding-left: 55px;
    section{
        flex: 0.98;
        height: 100%;
        overflow-y: scroll;
        scrollbar-color: $grisLight transparent;
        scrollbar-width: thin;

        nav{
            width: 500px;
            display: flex;
            border-radius: 10px;
            margin-bottom: 25px;
            overflow: hidden;
            border: 1px solid $grisLight;

            a{
                width: 25%;
                display: block;
                box-sizing: border-box;
                text-decoration: none;
                color: $gris;
                text-align: center;
                font-family: "interL";
                height: 40px;
                font-size: 0.75em;
                line-height: 42px;

                &.select{
                    background-color: $vert;
                    color: $blanc_casse;
                }
            }
        }

    }
    
    // aside{
    //     width: 250px;
    //     // border: 1px solid $vert;
    // }
}