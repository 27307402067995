@import "./../../App.scss";

.lecons {

    h1 {
        font-weight: normal;
        font-size: 1em;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
    }

    .multipleChoice {
        width: 350px;
        margin: auto;
        justify-content: center;
        font-size: 0.8em;

        button {
            transform: scale(1.1);
        }

    }

    header {
        margin: 0;

        &>img:nth-child(1) {
            transform: rotate(180deg)
        }

        img {
            cursor: pointer;
            background-color: $vert;
            border-radius: 100px;
        }

        p {
            text-align: center;
            font-weight: normal;
            font-family: 'interM';
            color: $gris;
            font-size: 1.1em;

            span {
                background-color: $vert;
                padding: 1px;
                color: $blanc_casse;
                font-family: 'interR';
            }
        }
    }

    .switchs {
        margin: 0;
        margin-top: 25px;

        .switch {
            display: inline-block;
            margin-right: 15px;
        }
    }

    .tableau {
        // border: 1px solid blue;
        display: flex;
        justify-content: center;
        padding: 0;

        article {
            border-left: 1px solid $grisLight;
            width: calc(100% / 6);
            box-sizing: border-box;

            h3 {
                text-align: center;
                font-weight: normal;
                color: $gris;
                margin-bottom: 35px;
            }

            h4{
                border-bottom: 1px solid $gris;
                font-family: "interR";
                font-weight: normal;
                color: $gris;
                display: inline-block;
                margin-left: 10px;
            }

            .cellule {
                transition: transform 350ms ease-in-out;
                border: 1px solid $vert;
                width: 75%;
                margin: auto;
                border-radius: 6px;
                padding: 10px;
                box-sizing: border-box;
                cursor: pointer;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                color: $vert;

                &.isError{
                    // background-color: $saumon;
                    border-color: $rouge;
                    color: $rouge !important;
                    .eleve{
                        color: $gris;
                        font-size:0.75em;
                    }
                    .moniteur{
                        font-size: 0.85em;
                        div{
                            font-size: 0.8em;
                            strong{
                                font-weight: normal;
                                font-size: 1.2em;
                                border: 1px solid $gris;
                                padding: 2px;
                                border-radius: 6px;
                                display: block;
                            }
                        }
                    }
                }
                &.isAnnulee{
                    background-color: $saumon;
                    border-color: $saumon;
                    color: $blanc_casse;
                    .eleve{
                        color: $blanc_casse;
                        font-size:0.75em;
                    }
                    .moniteur{
                        font-size: 0.85em;
                        div{
                            font-size: 0.8em;
                            strong{
                                font-weight: normal;
                                font-size: 1.2em;
                                border: 1px solid $gris;
                                padding: 2px;
                                border-radius: 6px;
                                display: block;
                            }
                        }
                    }
                }

                &.indispo {
                    background-color: $grisLight;
                    color: $gris;
                    border: none;
                    cursor: auto;
                }

                &.dispo{
                    color: $gris;
                }

                &.lecon {
                    background-color: $vert;
                    color: $blanc_casse;

                    .horaire {
                        font-family: 'interM';
                        font-size: 0.8em;
                    }

                    .moniteur {
                        color: $grisXLight;
                    }
                }

                .ba {
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    line-height: 12px;
                    text-align: center;
                    top: -1px;
                    right: -1px;
                    border-radius: 6px;
                    color: $blanc_casse;
                    background-color: $saumon;
                    font-size: 0.55em;
                    padding: 2px;
                }

                .horaire {
                    font-size: 0.7em;
                    text-align: center;
                    width: 100%;
                }

                .eleve,
                .libre {
                    text-align: center;
                    color: $blanc_casse;
                    margin-top: 5px;
                    font-size: 0.9em;
                }

                .moniteur {
                    font-size: 0.6em;
                    text-align: center;
                    margin-top: 5px;
                }
            }
        }
    }
}