@import './../../App.scss';

.eleves,
.moniteurs {

    header {
        width: 95%;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a{
            text-decoration: none;
        }

        input {
            margin: 0;
            border: 1px solid $gris;
            display: block;
            font-size: 0.8em;
            width: 15%;
            height: 25px;
            margin-right: 25px;
            margin-left: 50px;
            padding: 2px;
            border-radius: 6px;
        }

        .numbers{
            color: $gris;
            font-size: 0.75em;
        }
    }

    table {
        color: $grisClair;
        font-size: 0.9em;
        width: 95%;
        margin: auto;
        margin-top: 25px;

        .ligneEleve {
            .nomAgence {
                font-size: 0.6em;
                padding: 0 20px 0 20px;

                span {
                    width: 40px;
                }
            }
        }

        tr {

            &.radie{
                td{
                    color: $grisLight;
                    text-decoration: line-through;
                }
            }

            td {
                padding-top: 10px;
                height: 35px;
                color: $gris;
                vertical-align: middle;
                text-align: center;

                &.type-permis {
                    color: $vert;
                    font-size: 0.8em;
                }

                &.paye,
                &.effectue,
                &.annule,
                &.absent {
                    font-family: "interL";
                    font-size: 0.75em;

                    strong {
                        font-weight: normal;
                        font-family: "interM";
                        color: $vert;
                        font-size: 1.5em;
                    }
                }

                &.absent strong {
                    color: $saumon !important;
                }

                &.fiche {
                    text-align: right;

                    a {
                        font-family: "interL";
                        border-radius: 6px;
                        padding: 4px;
                        box-sizing: border-box;
                        font-size: 0.65em;
                        color: $blanc_casse;
                        background-color: $vert;
                        border: 1px solid $vert;
                        text-decoration: none;
                        font-style: normal;
                        transition: background-color 200ms ease-in, color 125ms 50ms ease-out;

                    }
                }

                //Les moniteurs

                &.nomAgence {
                    font-size: 0.75em;
                    font-family: 'interL';
                    text-align: center;
                    padding: 0 30px 0 30px;

                    span {
                        display: inline-block;
                        padding: 5px;
                        width: 50px;
                        border-radius: 5px;
                        background-color: $mauve;
                        color: $blanc_casse;

                        &.capus {
                            background-color: $orange;
                        }
                    }
                }

                &.boutons {

                    button {
                        margin: 10px;
                    }
                }

                &.horaire {
                    padding: 0 5px 0 5px;
                    border: 1px solid $grisLight;

                    span {
                        width: 30px;
                        height: 17px;
                        line-height: 17px;
                        font-size: "interL";
                        display: inline-block;
                        border-radius: 5px;
                        color: $blanc_casse;
                        background-color: $vert;
                        padding-left: 1px;
                        font-size: 0.8em;
                        box-sizing: border-box;
                    }

                    &.jour,
                    &.ampm {
                        font-family: "interR";
                        font-size: 0.85em;
                    }

                    &.h {
                        font-size: 0.9em;
                    }
                }

            }
        }
    }
}