@import './../../App.scss';

.agences {

    color: $gris;

    .liste-agences {

        article {

            margin-bottom: 35px;
            border-bottom: 1px solid $grisLight;

            h2 {
                button {
                    display: inline-block;
                    position: absolute;
                    right: 12px;
                    font-size: 0.45em;
                }
            }

            p {
                position: relative;

                // border: 1px solid red;
                strong {
                    font-weight: normal;
                    font-family: "interM";
                }

                em {
                    font-style: normal;
                    padding: 3px;
                    min-width: 18px;
                    height: 18px;
                    text-align: center;
                    display: inline-block;
                    background-color: $vert;
                    color: $blanc_casse;
                    margin-left: 2px;
                }
            }
        }
    }
}