@import './../../App.scss';

.admins {

    .ajoutAdmin {
        color: $gris;
        h1,
        h2 {
            font-weight: normal;
            font-family: "interM";
        }
        .contenu{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            input{
                padding-left: 10px;
                box-sizing: border-box;
            }

            input, button{
                margin-bottom: 10px;
            }
        }
    }

    margin-top: 50px;

    section {
        margin-top: 25px;
        color: $gris;
        width: 85%;
        min-width: 550px;


        article {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .boutons {
                display: flex;

                button {
                    margin-right: 5px;
                }
            }


            strong {
                font-weight: normal;
                background-color: $vert;
                color: $blanc_casse;
                padding: 2px;
                font-size: 0.9em;
            }
        }
    }
}