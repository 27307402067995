@import './../../App.scss';

.eleve {
    color: $grisClair;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .c {
        flex: 0.95;
        padding: 0;
        min-width: 420px;

        h2 {
            text-align: center;
            text-transform: uppercase;
            font-weight: normal;
            color: $gris;
            font-size: 1em;
            font-family: "interM";
            margin: 0;
        }

        .examens {
            border-bottom: 1px solid $vert;
            padding-bottom: 15px;
            margin-bottom: 30px;

            .listeExams {
                li {
                    font-family: "interR";
                    margin-bottom: 15px;
                    color: $gris;
                    position: relative;

                    button {
                        position: absolute;
                        right: 0;
                    }

                    &.past {
                        color: $grisLight;
                    }

                    strong {
                        color: $saumon;
                    }

                    span {
                        font-family: "interR";
                        font-size: 0.9em;
                        font-style: italic;
                    }
                }
            }
        }

        .listeLessons {
            font-size: 0.87em;

            &>div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    margin-bottom: 10px;
                    color: $gris;

                    strong {
                        background-color: $vert;
                        color: $blanc_casse;
                        width: 35px;
                        text-align: center;
                        font-weight: normal;
                        display: inline-block;
                        font-size: 0.9em;
                        padding: 2px;
                        margin-right: 5px;
                    }

                    .monit {
                        // border: 1px solid blue;
                        font-weight: normal;
                        font-family: "interM";
                        color: black;
                    }

                    div {
                        font-family: "interM";
                        display: block;
                        margin-top: 5px;
                        opacity: 0.8;
                    }

                    button {
                        display: inline-block;
                        width: 50px;
                        height: 25px;
                        padding: 2px !important;
                    }
                }
            }

            .past,
            .absent {

                strong {
                    background-color: $grisLight;
                    font-weight: normal;
                    font-size: 0.9em;
                    padding: 2px;
                }
            }

            .absent {
                strong {
                    background-color: $saumon;
                }
            }
        }

    }


    .d {
        width: 45%;
        min-width: 450px;

        .text {
            min-height: 250px;
            margin-top: 50px;
            margin-bottom: 50px;

            h1 {
                margin: 0;
            }

            button {
                margin-bottom: 5px;
                transition: opacity 250ms ease-in-out;
            }

            textarea {
                width: 100%;
                border: 1px solid $gris;
                border-radius: 6px;
                box-sizing: border-box;
                padding: 10px;
                height: 500px;
            }
        }

        .flex2 {

            &.ligne1 {
                margin-bottom: 50px;
            }

            display: flex;
            // border: 1px solid purple;
            padding: 0;

            h1 {
                font-size: 1.5em;
                font-weight: normal;
                font-family: "interL";
                margin: 0;
                margin-top: 5px;
            }

            .radie {
                // border: 1px solid blue;
                font-family: "interB";
                color: $saumon;
            }

            h2 {
                font-size: 0.75em;
                font-weight: normal;
                font-family: "interL";
                margin: 0;
                margin-top: 5px;
            }

            .adresse {
                // border: 1px solid blue;
                font-size: 0.85em;
                font-family: "interL";
                margin-top: 35px;

                p {
                    margin: 0;

                    em {
                        font-size: 0.9em;
                        font-style: normal;
                        margin-top: 5px;
                        display: inline-block;
                    }

                }

                .obtentionCode {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    strong {
                        padding: 5px;
                        color: $blanc_casse;
                        background-color: $vert;
                        font-weight: normal;
                        margin-right: 5px;
                    }

                    em {
                        margin-right: 5px;
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                button {
                    margin-bottom: 10px;
                }
            }

            .type_financement {
                margin-top: 10px;
                font-family: "interR" !important;
                color: $vert;
            }

            .stats {
                font-size: 0.8em;
                font-family: "interL";

                .type_permis,
                .type_conduite {
                    color: $vert;
                    font-size: 1em;
                    font-family: "interM";
                    margin-bottom: 0;
                }

                .type_conduite {
                    margin-top: 0;
                    font-family: "interR";
                    color: $vert;
                }

                strong {
                    color: $vert;
                    font-weight: normal;
                    font-family: "interM";
                    font-size: 1.15em;
                }

                .absent {
                    strong {
                        color: $saumon;
                    }
                }

                .buttons {
                    margin-top: 25px;

                    button {
                        width: 120px;
                        font-size: 0.8em;
                        margin-top: 15px;
                    }
                }
            }
        }

        .supprimer {
            text-align: center;
            color: $blanc_casse;
            background-color: #e41e18;
            height: 45px;
            line-height: 45px;
            margin-bottom: 50px;
            cursor: pointer;
            border-radius: 6px;
            position: relative;
            overflow: hidden;
            z-index: 0;

            &:hover {
                &::after {
                    transform: scale(75);
                }
            }

            &::after {
                content: "";
                position: absolute;
                top: calc(50% - 5px);
                left: calc(50% - 5px);
                height: 10px;
                width: 10px;
                border-radius: 5px;
                background-color: $blanc_casse;
                z-index: 1;
                opacity: 0.15;
                transform: scale(0);
                transition: transform 350ms ease-in-out;
            }

        }
    }

    .ajoutHeures {

        height: 100px;

        .contenu {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            input {
                width: 160px;
            }
        }
    }
}