$vert: #54BA78;
$blanc_casse: #F3FDF7;
$saumon: #FE8873;
$mauve: #561f7f;
$orange: #b9242e;
$rouge: #e41e18;
$bleu: #1A6077;
$gris: #555555;
$grisLight: #AFAFAF;
$grisXLight: #EAEAEA;
$grisClair: #797979;

@keyframes load {
    100% {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'interL';
    src: url('./fonts/Inter-Light.ttf')
}


@font-face {
    font-family: 'interR';
    src: url('./fonts/Inter-Regular.ttf')
}

@font-face {
    font-family: 'interM';
    src: url('./fonts/Inter-Medium.ttf')
}

@font-face {
    font-family: 'interB';
    src: url('./fonts/Inter-Bold.ttf')
}

html {
    font-family: "interR";
    height: 100%;
    width: 95%;
    margin: 0;
    margin: auto;
    box-sizing: border-box;

    body {
        margin: 0;
        height: 100%;
        box-sizing: border-box;

        #root {
            margin: 0;
            height: 100%;
            box-sizing: border-box;

            &>div {
                margin: 0;
                height: 100%;
                box-sizing: border-box;
                // border: 1px solid blue;
            }
        }
    }
}

button {
    border: none;
    cursor: pointer;
    display: block;
    width: 80px;
    font-size: 0.65em;
    font-family: "interL";
    height: 30px;
    border-radius: 6px;
    padding: 12px 9px 12px 9px;
    color: $blanc_casse;
    line-height: 0;
    background-color: $vert;

    &.creux {
        background-color: white;
        border: 1px solid $vert;
        color: $vert;

        &.saumon {
            border-color: $saumon;
            color: $saumon;
            background-color: white;
        }
    }

    &.saumon {
        background-color: $saumon;
    }
}

.loader {
    text-align: center;
    font-size: 1.75em;
    color: $gris;

    .loading {
        width: 25px;
        display: inline;
        margin-left: 10px;
        vertical-align: middle;
        height: 25px;
        animation: load 2s ease-in-out infinite;
    }
}

.cross {
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: $grisLight;
    color: white;
    width: 34px;
    height: 34px;
    border-radius: 18px;
    cursor: pointer;

    img {
        width: 60%;
        position: absolute;
        top: 20%;
        left: 20%;
        transform: rotate(45deg);
    }
}

.flottant {
    font-family: "interL";
    position: fixed;
    width: 360px;
    left: calc(50% - 180px);
    top: 280px;
    background-color: $blanc_casse;
    box-shadow: 0px 8px 20px 10px rgba($color: $gris, $alpha: 0.25);
    border: 1px solid $grisXLight;
    border-radius: 12px;
    z-index: 100;
    padding: 10px;
    transition: opacity 250ms ease-in;

    h1 {
        font-size: 1em;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 0.75em;
    }

    h1,
    h2 {
        font-weight: normal;
        text-align: center;
        margin: 0;
    }

    input {
        height: 35px;
        border-radius: 6px;
        background-color: white;
        border: 1px solid $grisLight;
        font-size: 1em;
        box-sizing: border-box;
        font-family: "interR" !important;
        color: $grisClair !important;
    }
}

.vert {
    color: $vert;
}

.strong {
    font-family: "interB";
    font-size: 1.2em !important;
}

.multipleChoice {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin-left: 5px;
    margin-bottom: 10px;

    button {
        width: 100px !important;
        font-size: 0.8em;
        margin: 0 !important;
        margin-bottom: 5px !important;
    }
}